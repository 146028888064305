<template>
  <div class="reference-container">
    <div class="container-reference bg-slide">
      <span class="mask bg-gradient-primary opacity-6"></span>
      <div class="wrap-reference">
        <div class="row">
          <div class="col-12">
            <h1 class="t-custom">
              Open Account<br />
              A&amp;B MONEY PLUS
            </h1>
          </div>

          <div class="col-12 mb-4">
            <span>
              Opening Account with us to get extra more benefits and make your life easier. You have no worries about answering account questions. Our account come with Mastercard with 2 currencies option are GBP and EUR to offer you the great benefit. This will make your travel life be easier. You can shopping or withdraw cash from all cash machine (ATM) across the world.
            </span>
          </div>

          <div class="col-12 mb-4">
            <h2 class="text-center text-xl">
              Click button to process an easy registration process
            </h2>
          </div>

          <div class="col-12">
            <div class="row">
              <div class="col-md-6 text-center pb-4">
                <a :href="'https://abmoneyplus.com/ab-plus-register-customers?ref='+membercode" class="btn btn-primary btn-lg text-xl box">
                    New Customer<br>ลูกค้าใหม่
                </a>
              </div>
              <div class="col-md-6 text-center pb-4">
                <a :href="'https://abmoneyplus.com/abplus-exist-customer?ref='+membercode" class="btn btn-default btn-lg text-xl box">
                    Exist Customer<br>ลูกค้าเก่า
                </a>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      membercode: this.$route.params.member_code
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/scss/reference.scss";
</style>